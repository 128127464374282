/** @format */

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import LockResetIcon from "@mui/icons-material/LockReset";

import {
  Button,
  Collapse,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ExpandLess, Public } from "@mui/icons-material";

import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import QuizIcon from "@mui/icons-material/Quiz";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SchoolIcon from "@mui/icons-material/School";
import DeveloperModeRoundedIcon from "@mui/icons-material/DeveloperModeRounded";
import ReactPlayer from "react-player";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import DownloadIcon from "@mui/icons-material/Download";
// import StudentResetPassword from "./StudentResetPassword";

import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { get } from "../../services/apiMethods";
import { PRIVATE_URLS } from "../../services/urlConstants";
import SettingContext from "../../context/SettingsContext";

//==========chapter icons=========//
const ICONS = {
  FlashCard: <ViewAgendaIcon fontSize="small" color="warning" />,
  Quiz: <QuizIcon fontSize="small" color="success" />,
  Video: <YouTubeIcon fontSize="small" color="error" />,
  Material: <InsertDriveFileIcon fontSize="small" color="info" />,
  CodePractice: (
    <DeveloperModeRoundedIcon fontSize="small" sx={{ color: "#ffd600" }} />
  ),
};

const styles = {
  borderRadius: "5px",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 10,
  p: 4,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function StudetCourseContent() {
  const navigate = useNavigate();
  const { selectedSetting } = useContext(SettingContext);
  const theme = useTheme();
  const { id } = useParams();
  const [selectedQuiz, setSelectedQuiz] = useState([]);
  const [quizScore, setQuizScore] = useState(0);
  const [showChapter, setShowChapter] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [quizLoading, setQuizLoading] = useState(false);
  const [selectedQuizCompleteStatus, setSelectedQuizCompleteStatus] =
    useState(false);
  const [ResetPassword, setResetPassword] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const [chapter, setChapter] = React.useState([]);
  const [openChapters, setOpenChapters] = React.useState([]);
  const [selectedContent, setSelectedContent] = React.useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [activeYear, setActiveYear] = React.useState([]);

  useEffect(() => {
    let student = window.localStorage.getItem(
      process.env.REACT_APP_CURRENT_USER
    );
    if (student) {
      setCurrentStudent(JSON.parse(student));
    }
  }, [process.env.REACT_APP_CURRENT_USER]);

  useEffect(() => {
    if (selectedContent) {
      for (let chapt of chapter) {
        if (
          chapt.contents.filter((c) => c._id === selectedContent._id).length
        ) {
          setShowChapter(chapt);
        }
      }
    }
  }, [selectedContent]);

  useEffect(() => {
    if (chapter && chapter.length > 0) {
      const firstChapter = chapter[0];
      setSelectedContent(firstChapter.contents[0]);
    }
  }, [chapter]);

  useEffect(() => {
    getCourse();
    getActiveAcademicYear();
  }, [selectedSetting]);

  const getCourse = async () => {
    try {
      const { data } = await get(PRIVATE_URLS.course.myCourses, {
        params: { schoolId: selectedSetting._id },
      });

      setCourseList(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //get academic year
  const getActiveAcademicYear = async () => {
    try {
      const { data } = await get(PRIVATE_URLS.academicYear.list);
      setActiveYear(data.result[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getContent = async () => {
    try {
      const { data } = await get(
        `${PRIVATE_URLS.courseContent.getDetailsStudents}/${id}`
      );

      const filter = data.result.chapters?.filter((m) => m.contents.length > 0);
      setChapter(filter);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContent();
  }, [id]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    window.localStorage.removeItem(process.env.REACT_APP_TOKEN);
    window.localStorage.removeItem(process.env.REACT_APP_USER_TYPE);
    window.localStorage.removeItem(process.env.REACT_APP_ROLE);
    window.localStorage.removeItem(process.env.REACT_APP_CURRENT_USER);
    window.localStorage.removeItem(process.env.REACT_APP_PROFILE_ID);
    navigate("/login");
  };
  const handleContentClick = (content) => {
    setSelectedContent(content);
  };

  const handleToggleCollapseChapter = (index, chapters) => {
    let indexExists = openChapters.filter((i) => i === index)[0];
    if (indexExists || indexExists === 0) {
      return setOpenChapters(openChapters.filter((i) => i !== index));
    } else {
      setOpenChapters([...openChapters, index]);
    }
  };

  const handleNext = async () => {
    try {
      const currentChapterIndex = chapter.findIndex(
        (m) => m.contents && m.contents.includes(selectedContent)
      );

      if (currentChapterIndex !== -1) {
        const currentChapter = chapter[currentChapterIndex];
        const currentContentIndex = currentChapter.contents.findIndex(
          (content) => content === selectedContent
        );

        if (currentContentIndex < currentChapter.contents.length - 1) {
          const nextContent = currentChapter.contents[currentContentIndex + 1];
          setSelectedContent(nextContent);
          handleContentClick(nextContent);
        } else if (currentChapterIndex < chapter.length - 1) {
          const nextChapter = chapter[currentChapterIndex + 1];
          const firstContentOfNextChapter = nextChapter.contents[0];
          setSelectedContent(firstContentOfNextChapter);
          handleContentClick(firstContentOfNextChapter);
        } else {
          return;
        }
      }
    } catch (error) {
      console.error("Error in handleNext:", error);
    }
  };

  const onVideoEnd = () => {
    handleNext();
  };

  useEffect(() => {
    if (selectedContent && selectedContent.type == "Quiz") {
      let newQuizList = selectedContent.quiz.map((s) => ({
        question: s.question,
        options: s.options.map((o) => o.name),
        correctOption: s.correctOption,
        givenAnswer: "",
      }));

      let currentCourse = courseList.filter((c) => c._id == id)[0];
      let chapter = currentCourse?.chapters?.filter(
        (c) => c.contents?.filter((c) => c._id == selectedContent._id)[0]
      )[0];
      let content = chapter?.contents?.filter(
        (c) => c._id == selectedContent._id
      )[0];

      setSelectedQuiz(newQuizList);
      if (content?.completed) {
        setSelectedQuizCompleteStatus(true);
        setQuizScore(content.score);
      } else {
        setSelectedQuizCompleteStatus(false);
        setQuizScore(0);
      }
    }
  }, [selectedContent]);

  const handleAnswerChange = (event, questionIndex, optionIndex) => {
    let question = selectedQuiz[questionIndex];
    let selectedAnswer = question.options[optionIndex];
    let updatedQuizQuestion = { ...question, givenAnswer: selectedAnswer };
    setSelectedQuiz(
      selectedQuiz.map((s, i) =>
        i === questionIndex ? updatedQuizQuestion : s
      )
    );
  };

  const handleQuizSubmit = async () => {
    setQuizLoading(true);

    if (!selectedQuiz) return;
    let totalQuestions = selectedQuiz.length;
    if (selectedQuiz.filter((q) => !q.givenAnswer).length)
      return toast.error("Please select all the answers before submitting!");

    let totalCorrectAnswers = selectedQuiz.filter(
      (s) => s.correctOption == s.givenAnswer
    ).length;

    setQuizScore(totalCorrectAnswers);
    setSelectedQuizCompleteStatus(true);
    setQuizLoading(false);
  };

  const handleDownload = (material) => {
    try {
      window.open(material, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handlePrevious = () => {
    const currentChapterIndex = chapter.findIndex(
      (m) => m.contents && m.contents.includes(selectedContent)
    );

    if (currentChapterIndex !== -1) {
      const currentChapter = chapter[currentChapterIndex];
      const currentContentIndex = currentChapter.contents.findIndex(
        (content) => content === selectedContent
      );

      if (currentContentIndex > 0) {
        const previousContent =
          currentChapter.contents[currentContentIndex - 1];
        setSelectedContent(previousContent);
        handleContentClick(previousContent);
      } else if (currentChapterIndex > 1) {
        const previousChapter = chapter[currentChapterIndex - 1];
        const lastContentOfPreviousChapter =
          previousChapter.contents[previousChapter.contents.length - 1];

        if (lastContentOfPreviousChapter) {
          setSelectedContent(lastContentOfPreviousChapter);
          handleContentClick(lastContentOfPreviousChapter);
        } else {
          const lastChapter = chapter[chapter.length - 1];
          const lastContentOfLastChapter =
            lastChapter.contents[lastChapter.contents.length - 1];

          setSelectedContent(lastContentOfLastChapter);
          handleContentClick(lastContentOfLastChapter);
        }
      } else if (currentChapterIndex === 1) {
        const firstChapter = chapter[0];
        const lastContentOfFirstChapter =
          firstChapter.contents[firstChapter.contents.length - 1];

        setSelectedContent(lastContentOfFirstChapter);
        handleContentClick(lastContentOfFirstChapter);
      }
    } else if (chapter.length > 1) {
      const secondLastChapter = chapter[chapter.length - 2];
      const lastContentOfSecondLastChapter =
        secondLastChapter.contents[secondLastChapter.contents.length - 1];

      if (lastContentOfSecondLastChapter) {
        setSelectedContent(lastContentOfSecondLastChapter);
        handleContentClick(lastContentOfSecondLastChapter);
      } else {
        const lastChapter = chapter[chapter.length - 1];
        const lastContentOfLastChapter =
          lastChapter.contents[lastChapter.contents.length - 1];

        setSelectedContent(lastContentOfLastChapter);
        handleContentClick(lastContentOfLastChapter);
      }
    }
  };

  if (!chapter) return null;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}>
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            color="white"
            textAlign="center"
            fontSize="18px"
            sx={{ display: { xs: "none", md: "block" } }}>
            {selectedSetting.name} [{activeYear ? activeYear.from : "-"} -{" "}
            {activeYear ? activeYear.to : "-"}]
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={1}
              direction="row">
              <Tooltip title="Account Settings">
                <IconButton
                  onClick={handleProfileMenuOpen}
                  // size="small"
                  // sx={{ ml: 2 }}
                  aria-controls={openProfile ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfile ? "true" : undefined}>
                  <Avatar
                    src={currentStudent?.photo}
                    sx={{ width: 26, height: 26 }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Website">
                <Link to="/">
                  <IconButton>
                    <Avatar
                      src="/world-wide-web.png"
                      alt="loading..."
                      sx={{ width: 26, height: 26 }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            </Stack>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            sx={{ width: 227 }}
            open={openProfile}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem>
              <Link
                to="/sch/student_profile"
                style={{ display: "flex", textDecoration: "none" }}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>Profile</ListItemText>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/sch/student_reset_password"
                style={{ display: "flex", textDecoration: "none" }}>
                <ListItemIcon>
                  <LockResetIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                  Reset Password
                </ListItemText>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText> Log Out</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}>
            <img
              src={selectedSetting?.logo}
              alt="image"
              style={{ height: "50px", width: "80px", objectFit: "contain" }}
            />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundColor: "#ffffff5c" }} />

        {chapter?.map((chapters, index) => (
          <List key={chapters._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemButton
              onClick={() => handleToggleCollapseChapter(index, chapters)}
              sx={{
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "#fff",
                },
              }}>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <SchoolIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-primary": {
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "black",
                  },
                  paddingLeft: "20px",
                }}
                primary={chapters.title.substring(0, 15)}
              />
              {openChapters.filter((i) => i === index)[0] === index ||
              chapters.contents.filter(
                (c) => c._id == selectedContent?._id
              )[0] ? (
                <ExpandLess
                  style={{
                    marginLeft: "8px",
                    color: "black",
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{
                    marginLeft: "8px",
                    color: "black",
                  }}
                />
              )}
            </ListItemButton>
            <Collapse
              in={
                openChapters.filter((i) => i === index)[0] === index ||
                chapters.contents.filter(
                  (c) => c._id == selectedContent?._id
                )[0]
              }
              timeout="auto"
              unmountOnExit>
              <List component="div" disablePadding>
                {chapters?.contents?.map((content, ind) => (
                  <ListItemButton
                    selected={selectedContent?._id == content._id}
                    onClick={() => handleContentClick(content)}
                    key={ind}
                    // sx={{ pl: 4 }}
                    style={{
                      fontSize: "26px",
                      paddingRight: "10px",
                      backgroundColor:
                        selectedContent?._id === content._id
                          ? "whitesmoke"
                          : "inherit",
                    }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      {ICONS[content.type]}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          display: !open ? "none" : "block",
                          fontWeight: "bold",
                          fontSize: "13px",
                          // color: "#fff",

                          paddingLeft: "10px",
                        },
                      }}
                      primary={content.title?.substring(0, 25) || content.type}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        ))}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Box
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}>
          {chapter.map(
            (m) =>
              m.material &&
              m.contents.map((con) =>
                selectedContent && con._id === selectedContent._id ? (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(m.material)}>
                    Chapter Material
                  </Button>
                ) : null
              )
          )}

          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
              }}>
              {showChapter?.title}
            </Typography>
            <ChevronRightIcon
              sx={{ color: `${theme.palette.secondary.dark}` }}
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: 16,
              }}>
              {selectedContent?.title}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
            }}>
            <Button
              variant="contained"
              onClick={handlePrevious}
              size="small"
              sx={{
                background: "#eeeeee",
                color: "black",
                minWidth: "40px",
                height: "40px",
                "&:hover": {
                  background: "primary",
                  color: "#fff",
                },
              }}>
              <SkipPreviousIcon />
            </Button>

            <Button
              variant="contained"
              onClick={handleNext}
              size="small"
              sx={{
                background: "#eeeeee",
                color: "black",
                minWidth: "40px",
                height: "40px",
                "&:hover": {
                  background: "primary",
                  color: "#fff",
                },
              }}>
              <SkipNextIcon />
            </Button>
          </Box>
        </Box>
        <Box mt={10} />
        <Box>
          {selectedContent?.type === "Video" && (
            <>
              <Box sx={{ height: { xs: "100%", sm: "100%", md: "78vh" } }}>
                <ReactPlayer
                  url={selectedContent.video}
                  controls={true}
                  height="100%"
                  width="100%"
                  onEnded={() => onVideoEnd()}
                  style={{
                    background: "black",
                    margin: "20px auto",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                />
              </Box>
            </>
          )}

          {selectedContent?.type === "FlashCard" && (
            <>
              <Box
                style={{
                  padding: "10px",
                  margin: "10px",
                  textAlign: "center",
                }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    // mt: "20%",
                  }}>
                  <Box sx={styles}>
                    {selectedContent.flashCard.cardType === "Image" ? (
                      <img
                        src={selectedContent?.flashCard.image}
                        alt="Image"
                        style={{ height: "auto", width: "100%" }}
                      />
                    ) : (
                      <Typography sx={{ textAlign: "justify" }}>
                        {selectedContent?.flashCard.text}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {selectedContent?.type === "Quiz" &&
            (selectedQuizCompleteStatus ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: "10px",
                  alignItems: "center",
                  flex: 1,
                  padding: "50px 0",
                }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Quiz Completed
                </Typography>
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  spacing={3}>
                  <Button
                    size="small"
                    disableRipple
                    disableTouchRipple
                    component="label"
                    variant="outlined"
                    sx={{ cursor: "none" }}>
                    Score : {quizScore}/{selectedContent.quiz.length}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => setSelectedQuizCompleteStatus(false)}>
                    Re-Take Quiz
                  </Button>
                </Stack>
              </Box>
            ) : (
              <>
                <Box>
                  {selectedQuiz.map((q, j) => (
                    <Paper
                      key={j}
                      elevation={0}
                      sx={{
                        padding: "10px",
                        backgroundColor: "whitesmoke",
                        my: 1,
                      }}>
                      <Grid item xs={12} md={6}>
                        <Typography fontSize="16px" fontWeight="bold" mb={1}>
                          {j + 1}. {q.question}
                        </Typography>
                      </Grid>

                      <Grid container>
                        {q.options.map((option, k) => (
                          <Grid item xs={6} sm={6} md={6} lg={6} key={k}>
                            <FormControl component="fieldset" mb={1}>
                              <RadioGroup
                                value={q.givenAnswer || ""}
                                onChange={(event) =>
                                  handleAnswerChange(event, j, k)
                                }>
                                <FormControlLabel
                                  value={option}
                                  control={
                                    <Radio
                                      size="small"
                                      style={{
                                        color: theme.palette.secondary.dark,
                                      }}
                                    />
                                  }
                                  label={option}
                                  style={{ padding: "0", margin: "0" }}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "10px",
                  }}>
                  <LoadingButton
                    variant="contained"
                    onClick={handleQuizSubmit}
                    sx={{
                      background: theme.palette.primary.dark,
                      "&:hover": {
                        background: theme.palette.secondary.dark,
                      },
                    }}
                    loading={quizLoading}>
                    Submit
                  </LoadingButton>
                </Box>
              </>
            ))}
          {selectedContent?.type === "CodePractice" && (
            <>
              <Box sx={{ mb: 2 }}>
                {" "}
                <Typography sx={{ fontSize: "small" }}>
                  {selectedContent?.description}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", height: "80vh", width: "100%" }}>
                <iframe
                  src="https://codesandbox.io/embed/ryk8vw?view=Editor+%2B+Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: 0,
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                  title="PracticeMakeManPerfect"
                  allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                  sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
              </Box>
            </>
          )}
          {selectedContent?.type === "Material" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "200px 0",
                }}>
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  endIcon={<DownloadIcon />}
                  onClick={() =>
                    handleDownload(selectedContent.contentMaterial)
                  }>
                  {selectedContent.contentMaterial.split("/").pop()}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
